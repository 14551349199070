export function getParam(param) {
    var dataCallbackMatches = new RegExp(param + '=([^&]+)').exec(document.location.href);
    if (dataCallbackMatches) {
      return dataCallbackMatches[1];
    } else {
      return null;
    }
  };


export const isDebug = getParam('debug') == 'true';
console.log("Debug logging: " + isDebug);
export const debug = isDebug ? 
      function (msg, ...objs) {
        console.log(msg, ...(objs.map(JSON.stringify)));
      }
      :
      function (...all) {};